<template>
    <component :is="filterItem.filterEditor" :filterObject="filterObject" :filterItem="filterItem"  :dropdown="dropdown" :hideOperators="hideOperators"></component>
</template>

<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        columnName: String,
        gridRef: null,
        dataObject: null,
        operator: null,
        filterItem: null,
        filterObject: null,
        dropdown: null,
        hideOperators:null
    });
</script>

<script>
    import { useAsyncComponent } from 'o365-vue-utils';

    const String = useAsyncComponent('./components.FilterEditor.String.vue', { importFn: () => import('./components.FilterEditor.String.vue') });
    const Number = useAsyncComponent('./components.FilterEditor.Number.vue', { importFn: () => import('./components.FilterEditor.Number.vue') });
    const Date = useAsyncComponent('./components.FilterEditor.Date.vue', { importFn: () => import('./components.FilterEditor.Date.vue') });
    const Distinct = useAsyncComponent('./components.FilterEditor.Distinct.vue', { importFn: () => import('./components.FilterEditor.Distinct.vue') });
    const Bit = useAsyncComponent('./components.FilterEditor.Bit.vue', { importFn: () => import('./components.FilterEditor.Bit.vue') });
    const BitOrNotExists = useAsyncComponent('./components.FilterEditor.BitOrNotExists.vue', { importFn: () => import('./components.FilterEditor.BitOrNotExists.vue') });
    const Time = useAsyncComponent('./components.FilterEditor.Time.vue');
    export default {
        components: { String, Number, Date, Distinct, Bit, BitOrNotExists, Time} 
    }
</script>
